@font-face {
    font-family: "RobotoRegular";
    src: url("./fonts/RobotoRegular/RobotoRegular.eot");
    src: url("./fonts/RobotoRegular/RobotoRegular.eot?#iefix")format("embedded-opentype"),
    url("./fonts/RobotoRegular/RobotoRegular.woff") format("woff"),
    url("./fonts/RobotoRegular/RobotoRegular.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}
/* font-family: "RobotoLight"; */
@font-face {
    font-family: "RobotoLight";
    src: url("./fonts/RobotoLight/RobotoLight.eot");
    src: url("./fonts/RobotoLight/RobotoLight.eot?#iefix")format("embedded-opentype"),
    url("./fonts/RobotoLight/RobotoLight.woff") format("woff"),
    url("./fonts/RobotoLight/RobotoLight.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}


html, body {
    min-height: 100%;
    height: auto;
    width: 100%;
    padding: 0;
    margin: 0;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: RobotoLight, Arial;
}


.form {
    width: 300px;
    padding: 10px 20px 0px;
    margin-top: -30px;
}
.form-header {
    font: 21pt RobotoRegular;
    color: #404040;
    text-align: center;
    margin: 18px 0px 16px;
}

@media screen and (max-height: 600px) {
    .form-header {
        margin-top: 0px;
    }
}

.input-field {
    outline: none;
    border: 1px solid #aaa;
    padding: 10px;
    margin-bottom: 10px;
    width: 100%;
    box-sizing: border-box;
    color: #444;
}

.button {
    text-align: center;
    background-color: #45af73;
    color: #fff;
    border-radius: 2px;
    cursor: pointer;
    padding: 10px;
    font-size: 16px;
    border: none;
    outline: none;
    box-sizing: border-box;
    text-decoration: none;
    font-family: RobotoLight;
    width: 100%;
    margin-bottom: 15px;
}
.button.disabled{
    background-color: rgba(69, 175, 115, 0.68);
}
 .form-link {
     text-decoration: none;
     color: #36ad6a;
     font-size: 13px;
 }

 .div-link {
     position: relative
 }


.error-message {
    color: red;
    width: 300px;
}

.button-lang {
    position: absolute;
    top: 15px;
    right: 20px;
    padding: 8px 12px;
    background-color: #efefef;
    border-radius: 4px;
    cursor: pointer;
}